import React from 'react';
import  ReactDOM  from 'react-dom';

import App from './App'; 
import './index.css';

import * as serviceWorker from "./serviceWorker";

// import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { HashRouter as Router, Route, Routes } from "react-router-dom";

import { Footer, Blog, Post } from "./containers";
import ArtiTicket  from "./projects/ArtiTicket";
import ArtiTicketUI  from "./projects/ArtiTicket_UI";
import  GamersGrail  from "./projects/GamersGrail";
import  BOTW  from "./projects/BOTWicons";
import BOTWUI from "./projects/BOTWicons_UI";
import DarkStar from "./projects/DarkStar";
import DarkStarUI from "./projects/DarkStar_UI";
import Minus from './projects/Minus';
import Lettie from './projects/Lettie';
import Infinitea from './projects/Infinitea';
import RevoltWear from './projects/RevoltWear';
import SRDIllustrations from './projects/SRDIllustrations_UI';



import HogwartsLegacy from "./projects/HogwartsLegacy";

import GD from "./pages/GD";
import UXUI from "./pages/UXUI";
import AboutMe from "./pages/AboutMe";

// import ScrollToTop from './components/ScrollToTop';



ReactDOM.render(
    <Router>
      {/* <Navbar /> */}
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="#ux-ui" element={<Blog />}>
          {/* <Route path="" element={<Posts />} /> */}
          {/* <Route path=":postSlug" element={<Post />} /> */}
        </Route>    
        <Route path="/Breath-of-the-Wild-icons-case-study" element={<BOTW />} />
        <Route path="/Breath-of-the-Wild-icons" element={<BOTWUI />} />
        <Route path="/Dark-Star-Case-Study" element={<DarkStar />} />
        <Route path="/Dark-Star" element={<DarkStarUI />} />
        <Route path="/Artiticket-Case-Study" element={<ArtiTicket />} />
        <Route path="/Artiticket" element={<ArtiTicketUI />} />
        <Route path="/Gamers-Grail-Case-Study" element={<GamersGrail />} />
        <Route path="/Avatars-Emojis" element={<SRDIllustrations />} />
        <Route path="/Lettie-and-Co" element={<Lettie />} />

        
        <Route path="/Minus-The" element={<Minus />} />
        <Route path="/Infinitea" element={<Infinitea />} />
        <Route path="/RevoltWear" element={<RevoltWear />} />

        <Route path="/gd" element={<GD />} />
        <Route path="/ux-ui" element={<UXUI />} />
        <Route path="/about" element={<AboutMe />} />

        {/* Removed URLS */}
        {/* <Route path="/Minecraft-Dungeons" element={<Minecraft />} /> */}
        {/* <Route path="/Hogwarts-Legacy-vs-Open-World-Games" element={<HogwartsLegacy />} /> */}
        {/* <Route path="/Waterfall-Abyss-Case-Study" element={<WA />} /> */}
        {/* <Route path="/Waterfall-Abyss" element={<WAUI />} /> */}

      </Routes>
      <Footer />
    </Router>,
  
    document.getElementById("root")
  );
  
  serviceWorker.unregister();
// ReactDOM.render(<App />, document.getElementById('root'));

