import React from 'react';
import { Photo } from '../../components';
import { photo01, photo02, photo03, photo06, photo09, photo10, photo11, photo12, photo13, photo14} from'./imports';
import './recent.css';


const Recent = () => {
 
  return (
    <>
    <div className="site__gallery section__padding" id="recent_work">
    
      <div className="site__gallery-container view-desktop">
        
        <div className="site__gallery-container_groupC">
            <Photo  imgUrl={photo10} title="RevoltWear" type="Visual Identity"  URL="/RevoltWear"/>
            <Photo  imgUrl={photo12} title="ArtiTicket" type="UX Design"  URL="/Artiticket"/>
            <Photo  imgUrl={photo14} title="Avatars & Emojis" type="Iconography"  URL="/Avatars-Emojis"/>
        </div>
        <div className="site__gallery-container_groupB">
            <Photo  imgUrl={photo03} title="Lettie & Co" type="Logo Design"  URL="/Lettie-and-Co"/>
            <Photo  imgUrl={photo06} title="Infinitea" type="Product Design" URL="/Infinitea"/>
            <Photo  imgUrl={photo02} title="Breath of the Wild Icons" type="Iconography"  URL="/Breath-of-the-Wild-icons"/>
            <Photo  imgUrl={photo01} title="Dark Star" type="UI Design" URL="/Dark-Star" />
        </div>
        <div className="site__gallery-container_groupB">
            <Photo  imgUrl={photo09} title="Minus The" type="Logo Design" URL="/Minus-The"/>
            <Photo  imgUrl={photo13} title="Coming Soon" type=""  URL=""/>
        </div>
      </div>

      <div className="site__gallery-container view-mobile">
        
        <div className="site__gallery-container_groupB">
          <Photo imgUrl={photo11} title="RevoltWear" type="Visual Identity"  URL="/RevoltWear"/>
          <Photo  imgUrl={photo12} title="ArtiTicket" type="UX Design"  URL="/Artiticket"/>
          <Photo  imgUrl={photo14} title="Avatars & Emojis" type="Iconography"  URL="/Avatars-Emojis"/>
          <Photo  imgUrl={photo03} title="Lettie & Co" type="Logo Design"  URL="/Lettie-and-Co"/>
          <Photo  imgUrl={photo06} title="Infinitea" type="Product Design" URL="/Infinitea"/>
          <Photo imgUrl={photo02} title="Breath of the Wild Icons" type="Iconography"  URL="/Breath-of-the-Wild-icons"/>
          <Photo  imgUrl={photo01} title="Dark Star" type="UI Design" URL="/Dark-Star" />
          <Photo  imgUrl={photo09} title="Minus The" type="Logo Design" URL="/Minus-The"/>
          <Photo  imgUrl={photo13} title="Coming Soon" type=""  URL=""/>

        </div>
       
      </div>


    </div>
        </>
  )
}

export default Recent