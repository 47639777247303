import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import ScrollToTop from '../components/ScrollToTop';
import  './projects.css';
import  { Navbar } from '../components';

import Gallery from '../containers/gallery/Gallery';
import { proj5_imgURL1, proj5_imgURL2, proj5_imgURL3, proj5_imgURL4,
    proj5_imgURL5, proj5_imgURL6 } from'./imports';
import {proj5_HimgURL1, proj5_HimgURL2, proj5_HimgURL3, proj5_HimgURL4, proj5_HimgURL5} from './hImgs-imports';

const Lettie = () => {


  return (
<>
    <Navbar />
    <ScrollToTop />
    <div className='site__project' id="topPage">
        <div className="site__project-header-imgCollage section__padding">
            <div className='site__project-header-imgCollage-groupA'>
                <img src={proj5_HimgURL1} alt="Minus brand guide cropped" />
            </div>
            <div className='site__project-header-imgCollage-groupB'>
                 <img src={proj5_HimgURL2} alt="Minus brand" />
            </div>
            <div className='site__project-header-imgCollage-groupC'>
                <img src={proj5_HimgURL3} alt="Minus brand" />
                <img src={proj5_HimgURL4} alt="Minus logo" />
            </div>
            <div className='site__project-header-imgCollage-groupD'>
                <img src={proj5_HimgURL5} alt="Minus brand guide cropped" />
            </div>
        </div>

   
        <div className="site__tldr section__padding">
            <div className="site__tldr-groupA" id="projectTitle-UI">
                <h1 className="solid__text-UI">Lettie & Co</h1>
            </div>
            <div className="site__tldr-groupA" id="project-UI">
                <p><strong>Deliverables: </strong>Logo and brand guide </p>
                <p><strong>Project: </strong>Develop a logo that embodies the values and reason behind Lettie & Co. </p>
                <p><strong>My Responsibilities: </strong>Logo development and visual identity</p>
                <p><strong>The Challenge: </strong>Create a visual identity that is inclusive, gender-neutral, and playful </p>
            </div>

            <div className="site__tldr-groupC">
                <p><strong>Completed: </strong>2019</p>
                <p><strong>Applications: </strong>Adobe Illustrator, Photoshop</p>
                <p><strong>Website:</strong>
                 <a className="site__project-link" href="https://lettieandco.com"> Lettieandco.com </a>
                </p>
            </div>
        </div>

        
        <div className='site__UX-Design section__padding'>

            <div className='snapshot-img'>
                  <img src={proj5_imgURL1} alt="Lettie & Co Brand Board" />
                  
            </div>
            
            <div className='site__2col'>
                <div className='site__2col-groupA'>
                  <img src={proj5_imgURL6} alt="Lettie & Co Logo Exploration" />
                </div>
                <div className='site__2col-groupB'>
                <img src={proj5_imgURL2} alt="Minus brand guide cover" />
                </div>
            </div>

            <div className='snapshot-img'>
                <img src={proj5_imgURL4} alt="Minus business cards mockup" />
            </div>

         
            

            <div className='snapshot-img'>
                <img src={proj5_imgURL5} alt="Minus business cards mockup" />
            </div>
            <div className='snapshot-img'>
                <img src={proj5_imgURL3} alt="Minus business cards mockup" />
            </div>

            <div className='site__back-to-top'>
                <Link to="#topPage" className="links-nav">Back to top</Link>
            </div>

        </div>

    </div>

    <div className='site__blog-heading'>
    <h1>Discover more.</h1>
    </div>
  
    <Gallery />
</>
  )
}

export default Lettie