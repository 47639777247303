import React from 'react';
import './header.css';
import { HashLink as Link } from 'react-router-hash-link';

const Header = () => {
  return (
    <div className="site__header section__padding" id="home">
          <div className="site__header-content site__2col">
              <h1 className="solid__text">Visual Designer</h1>
                
              <div className='site__intro-containter '>

                <div className='site__intro-content'>
                  <h5>Specializing in
                  <span style={{color: 'white'}}>  creative experiences that delivers quality and originality</span>.
                  </h5>
                </div>
              </div>
              

              {/* <div className='project-btn-container view-desktop site__2col-groupB'>
                <div className='project-btn'>
                  <Link to="/gd" className="links-nav">
                    Graphic Design 
                    <div className='svg__container basline'>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25" />
                      </svg>
                    </div>
                  </Link>
                </div>
                <div className='project-btn'>
                  <Link to="/ux-ui" className="links-nav">
                    UI / UX Design 
                    <div className='svg__container basline'>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25" />
                      </svg>
                    </div>
                  </Link>
                </div>
              </div> */}


              
          </div>
          {/* <div className="site__header-image crop">
            <img id="hPhoto" src={photo} alt="homepage graphic design"/>
          </div> */}

      </div>
  )
}

export default Header