import React, { useState } from 'react';
import { NavLink } from "react-router-dom";
import { HashLink as Link } from 'react-router-hash-link';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import './navbar.css';


const Navbar = () => {

  const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <div className="site__navbar">
      <div className="site__navbar-links"> 
        <div className="site__navbar-links_logo">
          {/* <img src={logo} alt="logo" /> */}
          <p><span>Taylor Siemens.</span></p>
                  
          {/* <i class="fa-solid fa-heart"></i><i class="fa-solid fa-heart"></i><i class="fa-solid fa-heart"></i> */}
         
        </div>
        <div className="site__navbar-links_container">
            <NavLink className="links-nav" to="/"><p>Home</p></NavLink>
            <Link to="/about" className="links-nav"><p>About</p></Link>
            {/* <p><a href="#possibility">Open AI</a></p> */}
            {/* <Link to="/#features" className="links-nav"><p>Skills</p></Link> */}
            {/* <Link to="/ux-ui" className="links-nav"><p>UI/UX</p></Link> */}
            {/* <Link to="/gd" className="links-nav"><p>Graphic Design</p></Link> */}

        </div>
      </div>

      <div className="site__navbar-sign">
        {/* <a href="mailto:siemenstaylor@gmail.com"><i class="fa-regular fa-envelope"></i></a>
        <a href="https://www.behance.net/itstayyy"><i class="fa-lg fa-brands fa-behance"></i></a>
        <a href="https://www.artstation.com/tayyy"><i class="fa-lg fa-brands fa-artstation"></i></a>
        <a href="https://www.instagram.com/itstayyy/?hl=en"><i class="fa-lg fa-brands fa-instagram"></i></a>
        <a href="https://twitter.com/Pixxel_Perfect"><i class="fa-brands fa-twitter"></i></a>
         */}
         <div className='project-btn'>
          <a className="links-footer" href="mailto:siemenstaylor@gmail.com">Let's chat</a>
        </div>
      </div>

      <div className="site__navbar-menu">
      {toggleMenu
          ? <RiCloseLine color="var(--color-general)" size={27} onClick={() => setToggleMenu(false)} />
          : <RiMenu3Line color="var(--color-general)" size={27} onClick={() => setToggleMenu(true)} />}
        {toggleMenu && (
        <div className="site__navbar-menu_container scale-up-center ">
          <div className="site_navbar-menu_container-links">
           <NavLink className="links-nav" to="/"><p>Home</p></NavLink>
           <Link to="/about" className="links-nav"><p>About</p></Link>
           {/* <Link to="/#features" className="links-nav"><p>Skills</p></Link> */}
            {/* <Link to="/ux-ui" className="links-nav"><p>UX/UI</p></Link> */}
            {/* <Link to="/gd" className="links-nav"><p>Graphic Design</p></Link> */}
          </div>
        </div>
        )}
          </div>
    </div>
  )
}

export default Navbar